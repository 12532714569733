<template>
  <div>
    <b-modal
      id="modal-modify-change-flight-result"
      :title="'1. Chọn hành trình đổi chuyến bay'"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      size="lg"
      no-close-on-backdrop
      no-enforce-focus
      header-class="py-50"
      body-class="px-25 py-0"
      footer-class="d-flex justify-content-center"
      @hide="hideHandle"
      @show="showHandle"
    >
      <b-row
        style="min-height: 550px"
        class="mx-0"
        no-gutters
      >
        <b-col
          cols="12"
          class="px-0"
        >
          <Results
            v-if="bookingData"
            :is-add-flight="false"
            :booking-data="bookingData"
          />
        </b-col>

        <!-- <b-col cols="3">
        <Filters />
        </b-col> -->
      </b-row>

      <template #modal-footer="{close}">
        <b-button
          size="md"
          variant="outline-secondary"
          class="center rounded-pill"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol, BRow, BModal, BButton,
} from 'bootstrap-vue'

import store from '@/store'

// import Filters from './filters.vue'
import modifyFlightStoreModule from '@reservation/reservation-modify/components/detail/flights-details/modifyFlightStoreModule'

export default {
  components: {
    BCol,
    BRow,
    BModal,
    BButton,
    // Filters,
    Results: () => import('../../result-search/index.vue'),

  },
  props: {
    bookingData: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup() {
    const MODIFY_FLIGHT_APP_STORE_MODULE_NAME = 'app-modify-flight'

    if (!store.hasModule(MODIFY_FLIGHT_APP_STORE_MODULE_NAME)) {
      store.registerModule(MODIFY_FLIGHT_APP_STORE_MODULE_NAME, modifyFlightStoreModule)
    }

    const showHandle = () => {
      //
    }

    const hideHandle = () => {
      store.dispatch('app-reservation/setTabIndex', 0)
      store.dispatch('app-reservation/setSelectedTrip', [])
      store.dispatch('app-reservation/deleteResultSearch')
      store.dispatch(`${MODIFY_FLIGHT_APP_STORE_MODULE_NAME}/resetStore`)
    }

    return {
      showHandle,
      hideHandle,
    }
  },
}
</script>
