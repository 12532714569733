var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-change-flight-result",
      "title": '1. Chọn hành trình đổi chuyến bay',
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "header-class": "py-50",
      "body-class": "px-25 py-0",
      "footer-class": "d-flex justify-content-center"
    },
    on: {
      "hide": _vm.hideHandle,
      "show": _vm.showHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])];
      }
    }])
  }, [_c('b-row', {
    staticClass: "mx-0",
    staticStyle: {
      "min-height": "550px"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm.bookingData ? _c('Results', {
    attrs: {
      "is-add-flight": false,
      "booking-data": _vm.bookingData
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }